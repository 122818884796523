import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import * as api from '../api';
import * as lib from '../lib';
import { Title } from './Title';
import { CacheContext, ConfigContext } from './Context';
import { UserContext } from './user';
import { Period, periodList, Currency } from './Report';
import { ItemSelecter as ItemSelecterNew } from './Selecter';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    property: {
        width: '200px',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 260,
    },
    rowtitle: {
        width: '300px',
    },
    tooltipWidth: {
        // backgroundColor: theme.palette.background.default,
        // color: theme.palette.getContrastText(theme.palette),
        maxWidth: 500,
    },
    floatRight: {
        // margin: 0,
        // top: 90,
        // bottom: 'auto',
        // left: 'auto',
        // right: 20,
        // position: 'fixed',
        // position: 'absolute',
        float: 'right',
    },
    titleHelp: {
        marginLeft: 20,
    },
}));

export const wrapAPI = async (
    h: string,
    params: api.ConversionsParam,
    cache
) => {
    const enabled = true;
    const key = new URLSearchParams(params as any).toString();
    let data;
    if (enabled && cache.value && key in cache.value) {
        console.log('cache hit ---');
        data = cache.value[key];
    } else {
        data = await api.API.conversions(h, params);
        cache.value[key] = data as any[];
        cache.setValue(cache.value);
    }
    return data;
};

const useSiteConversions = (
    period: Period,
    nonAffiliate: boolean,
    allUA: boolean
) => {
    const { id } = useParams<Record<string, string | undefined>>();
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const cache = useContext(CacheContext);
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let ids: string[];
            if (id) {
                ids = [id];
            } else {
                const p = await store.sites(
                    userStatus.user.email,
                    config.serviceId
                );
                ids = p.map((v) => v.id);
            }
            if (ids.length === 0) {
                setItems({ items: [] });
                setLoading(false);
                return;
            }
            const params = {
                start: period.start,
                end: period.end,
                promotionId: null,
                siteId: ids,
                sort: 'click_timestamp',
                serviceId: config.serviceId,
                sessionMode: config.sessionMode,
                nonAffiliate: nonAffiliate,
                allUA: allUA,
            } as api.ConversionsParam;
            const data = await wrapAPI(config.apiEndpoint, params, cache);
            setItems({
                items: data as any[],
            });
            setLoading(false);
        })();
    }, [userStatus, id, period, nonAffiliate, allUA]);

    return { items, loading };
};

const usePromotionConversions = (
    period: Period,
    nonAffiliate: boolean,
    allUA: boolean
) => {
    const { id } = useParams<Record<string, string | undefined>>();
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const cache = useContext(CacheContext);
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let ids: string[];
            if (id) {
                ids = [id];
            } else {
                const p = await store.promotions(
                    userStatus.user.email,
                    config.serviceId
                );
                ids = p.map((v) => v.id);
            }
            if (ids.length === 0) {
                setItems({ items: [] });
                setLoading(false);
                return;
            }
            const params = {
                start: period.start,
                end: period.end,
                promotionId: ids,
                siteId: null,
                sort: 'conversion_timestamp',
                serviceId: config.serviceId,
                sessionMode: config.sessionMode,
                nonAffiliate: nonAffiliate,
                allUA: allUA,
            } as api.ConversionsParam;
            const data = await wrapAPI(config.apiEndpoint, params, cache);
            setItems({
                items: data as any[],
            });
            setLoading(false);
        })();
    }, [userStatus, id, period, nonAffiliate, allUA]);

    return { items, loading };
};

const ClickInfo = ({ item, label }) => {
    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography>IP</Typography>
                    </TableCell>
                    <TableCell>{item[11]}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>UA</TableCell>
                    <TableCell>{item[12]}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Referer</TableCell>
                    <TableCell>{item[13]}</TableCell>
                </TableRow>
                {label && item[22] && (
                    <TableRow>
                        <TableCell>Label</TableCell>
                        <TableCell>{arrayJoin(item[22])}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

const ConversionInfo = ({ item, isPromotion }) => {
    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography>IP</Typography>
                    </TableCell>
                    <TableCell>{item[3]}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>UA</TableCell>
                    <TableCell>{item[4]}</TableCell>
                </TableRow>
                {isPromotion && (
                    <TableRow>
                        <TableCell>URL</TableCell>
                        <TableCell>{item[23]}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export const toCsv = (data: any[], isRaw?: boolean) => {
    if (!data) {
        return null;
    }
    const str = data.map((v) => v.join('\t')).join('\n');
    if (isRaw) {
        return str;
    }
    return URL.createObjectURL(
        new Blob([str], {
            type: 'text/plain',
        })
    );
};

const formatSiteLog = (data: any[]) => {
    return [
        [
            'Click',
            'Conversion',
            'Site',
            'Promotion',
            'Price',
            'Status',
            'ClickIP',
            'ClickUA',
            'ClickReferer',
            'ClickLabel',
            'LandingPage',
            'ConversionIP',
            'ConversionUA',
        ],
    ].concat(
        data.map((v) => [
            lib.xlsxTimestamp(v[6]),
            lib.xlsxTimestamp(v[0]),
            v[10],
            v[2],
            v[17],
            v[14],
            v[11],
            v[12],
            v[13],
            arrayJoin(v[22]),
            v[18],
            v[3],
            v[4],
        ])
    );
};

const arrayJoin = (data: any[]) => {
    if (data) {
        return data.join();
    }
    return;
};

const formatPromotionLog = (data: any[]) => {
    return [
        [
            'Conversion',
            'Click',
            'Promotion',
            'Site',
            'Hint',
            'Price',
            'Status',
            'ClickIP',
            'ClickUA',
            'ClickReferer',
            'LandingPage',
            'ConversionIP',
            'ConversionUA',
            'ConversionURL',
        ],
    ].concat(
        data.map((v) => [
            lib.xlsxTimestamp(v[0]),
            lib.xlsxTimestamp(v[6]),
            v[2],
            v[10],
            v[5],
            v[16],
            v[14],
            v[11],
            v[12],
            v[13],
            v[18],
            v[3],
            v[4],
            v[23],
        ])
    );
};

const useSites = () => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.sites(
                userStatus.user.email,
                config.serviceId
            );
            setItems(snaps);
        })();
    }, [userStatus]);

    return items;
};

const siteToList = (snaps: firebase.firestore.DocumentSnapshot[]) => {
    return [
        {
            name: 'All sites',
            id: null,
        },
    ].concat(
        snaps.map((snap) => {
            return {
                name: snap.get('displayName'),
                id: snap.id,
            };
        })
    );
};

export const LogSiteView = () => {
    const classes = useStyles();
    const history = useHistory();
    const sites = useSites();
    const sitesList = siteToList(sites);
    const [site, setSite] = useState(sitesList[0]);
    const [period, setPeriod] = useState(periodList[0]);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);
    const { items, loading } = useSiteConversions(period, nonAffiliate, allUA);

    useEffect(() => {
        console.log('LogSiteView.useEffect', site);
        if (site && site.id) {
            history.push(`/logs/sites/${site.id}`);
        } else {
            console.log('history.push 3');
            history.push(`/logs/sites`);
        }
    }, [site]);

    // if (loading) {
    //     return <LinearProgress />;
    // }

    return (
        <>
            <Title>
                Site's Log　
                {/* <Chip className={classes.titleHelp} label="Last 30 days" /> */}
                <Box className={classes.floatRight}>
                    {setAllUA && (
                        <Tooltip
                            title={
                                allUA
                                    ? 'exclude bot accesses'
                                    : 'include bot accesses'
                            }
                        >
                            <Checkbox
                                aria-label="allUA"
                                icon={<CloudOffIcon />}
                                checkedIcon={<CloudQueueIcon />}
                                name="allUA"
                                onChange={(e) => {
                                    setAllUA(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}
                    {setNonAffiliate && (
                        <Tooltip title="Include non affiliate click">
                            <Checkbox
                                aria-label="nonAffiliate"
                                icon={<VisibilityOffIcon />}
                                checkedIcon={<VisibilityIcon />}
                                name="nonAffiliate"
                                onChange={(e) => {
                                    setNonAffiliate(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title="Download">
                        <IconButton
                            aria-label="download"
                            onClick={(e) => {
                                lib.saveAsXlsx(
                                    formatSiteLog(items.items),
                                    'SiteLog'
                                );
                            }}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Title>

            <ItemSelecterNew
                items={sitesList}
                current={site}
                setCurrent={setSite}
            />
            <ItemSelecterNew
                items={periodList}
                current={period}
                setCurrent={setPeriod}
            />
            {/* <ItemSelecter label="sites" /> */}

            {loading || !items ? (
                <LinearProgress />
            ) : (
                <LogSiteTable items={items} />
            )}

            {/* <ItemList title="Promotion's Log" items={items.items} /> */}
        </>
    );
};

type LogSiteTableProps = {
    items: any;
};

export const LogSiteTable: React.FC<LogSiteTableProps> = ({ items }) => {
    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Click</TableCell>
                    <TableCell align="left">Conversion</TableCell>
                    <TableCell align="left">Site</TableCell>
                    <TableCell align="left">Promotion</TableCell>
                    {/* <TableCell align="left">promotion ID</TableCell>
                <TableCell align="left">promotion Name</TableCell> */}
                    {/* <TableCell align="left">conversion IP</TableCell> */}
                    {/* <TableCell align="left">conversion UA</TableCell> */}
                    {/* <TableCell align="left">partner ID</TableCell> */}
                    {/* <TableCell align="left">partner Name</TableCell> */}
                    {/* <TableCell align="left">site ID</TableCell> */}
                    {/* <TableCell align="left">click IP</TableCell> */}
                    {/* <TableCell align="left">click UA</TableCell> */}
                    {/* <TableCell align="left">referer</TableCell> */}
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Status</TableCell>
                    {/* <TableCell align="left">
                    conversion status Date
                </TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.items.map((item, i) => (
                    <TableRow key={i}>
                        <TableCell align="left">
                            <Tooltip
                                title={<ClickInfo item={item} label={true} />}
                                classes={{ tooltip: classes.tooltipWidth }}
                                arrow
                            >
                                <Box>
                                    {lib.DateTimeFormat(Date.parse(item[6]))}
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                            <Tooltip
                                title={
                                    <ConversionInfo
                                        item={item}
                                        isPromotion={false}
                                    />
                                }
                                classes={{ tooltip: classes.tooltipWidth }}
                                arrow
                            >
                                <Chip
                                    label={lib.DateTimeFormat(
                                        Date.parse(item[0])
                                    )}
                                    variant="outlined"
                                />
                            </Tooltip>
                        </TableCell>
                        <TableCell align="left">{item[10]}</TableCell>
                        <TableCell align="left">{item[2]}</TableCell>
                        {/* <TableCell align="left">{item[1]}</TableCell>
                    <TableCell align="left">{item[2]}</TableCell>
                    <TableCell align="left">{item[3]}</TableCell>
                    <TableCell align="left">{item[4]}</TableCell> */}
                        {/* <TableCell align="left">{item[7]}</TableCell> */}
                        {/* <TableCell align="left">{item[8]}</TableCell> */}
                        {/* <TableCell align="left">{item[9]}</TableCell> */}
                        {/* <TableCell align="left">{item[11]}</TableCell>
                    <TableCell align="left">{item[12]}</TableCell> */}
                        {/* <TableCell align="left">{item[13]}</TableCell> */}
                        <TableCell align="left">
                            <Currency num={item[17]} />
                        </TableCell>
                        <TableCell align="left">{item[14]}</TableCell>
                        {/* <TableCell align="left">{item[15]}</TableCell> */}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const usePromotions = () => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.promotions(
                userStatus.user.email,
                config.serviceId
            );
            setItems(snaps);
        })();
    }, [userStatus]);

    return items;
};

const promotionToList = (snaps: firebase.firestore.DocumentSnapshot[]) => {
    return [
        {
            name: 'All promotions',
            id: null,
        },
    ].concat(
        snaps.map((snap) => {
            return {
                name: snap.get('displayName'),
                id: snap.id,
            };
        })
    );
};

export const LogPromotionView = () => {
    const classes = useStyles();
    const history = useHistory();
    const promotions = usePromotions();
    const promotionsList = promotionToList(promotions);
    const [promotion, setPromotion] = useState(promotionsList[0]);
    const [period, setPeriod] = useState(periodList[0]);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);
    const { items, loading } = usePromotionConversions(
        period,
        nonAffiliate,
        allUA
    );

    useEffect(() => {
        console.log('LogPromotionView.useEffect', promotion);
        if (promotion && promotion.id) {
            history.push(`/logs/promotions/${promotion.id}`);
        } else {
            console.log('history.push 2');
            history.push(`/logs/promotions`);
        }
    }, [promotion]);

    // if (loading || !promotions) {
    //     return <LinearProgress />;
    // }
    console.log('LogPromotionView', promotionsList);
    return (
        <>
            <Title>
                Promotion's Log
                {/* <Chip className={classes.titleHelp} label="Last 30 days" /> */}
                <Box className={classes.floatRight}>
                    {setAllUA && (
                        <Tooltip
                            title={
                                allUA
                                    ? 'exclude bot accesses'
                                    : 'include bot accesses'
                            }
                        >
                            <Checkbox
                                aria-label="allUA"
                                icon={<CloudOffIcon />}
                                checkedIcon={<CloudQueueIcon />}
                                name="allUA"
                                onChange={(e) => {
                                    setAllUA(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}
                    {setNonAffiliate && (
                        <Tooltip title="Include non affiliate click">
                            <Checkbox
                                aria-label="nonAffiliate"
                                icon={<VisibilityOffIcon />}
                                checkedIcon={<VisibilityIcon />}
                                name="nonAffiliate"
                                onChange={(e) => {
                                    setNonAffiliate(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title="Download">
                        <IconButton
                            aria-label="download"
                            onClick={(e) => {
                                lib.saveAsXlsx(
                                    formatPromotionLog(items.items), // itemsがnullの場合の処理??
                                    'PromotionLog'
                                );
                            }}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Title>
            <ItemSelecterNew
                items={promotionsList}
                current={promotion}
                setCurrent={setPromotion}
            />
            <ItemSelecterNew
                items={periodList}
                current={period}
                setCurrent={setPeriod}
            />
            {/* <ItemSelecter label="promotions" /> */}

            {!items ? <LinearProgress /> : <LogPromotionTable items={items} />}

            {/* <ItemList title="Promotion's Log" items={items.items} /> */}
        </>
    );
};

type LogPromotionTableProps = {
    items: any;
};

export const LogPromotionTable: React.FC<LogPromotionTableProps> = ({
    items,
}) => {
    const classes = useStyles();
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="left">Conversion</TableCell>
                    <TableCell align="left">Click</TableCell>
                    <TableCell align="left">Promotion</TableCell>
                    <TableCell align="left">Site</TableCell>
                    <TableCell align="left">Hint</TableCell>
                    {/* <TableCell align="left">promotion ID</TableCell>
                <TableCell align="left">promotion Name</TableCell> */}
                    {/* <TableCell align="left">conversion IP</TableCell> */}
                    {/* <TableCell align="left">conversion UA</TableCell> */}
                    {/* <TableCell align="left">partner ID</TableCell> */}
                    {/* <TableCell align="left">partner Name</TableCell> */}
                    {/* <TableCell align="left">site ID</TableCell> */}
                    {/* <TableCell align="left">click IP</TableCell> */}
                    {/* <TableCell align="left">click UA</TableCell> */}
                    {/* <TableCell align="left">referer</TableCell> */}
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Status</TableCell>
                    {/* <TableCell align="left">
                    conversion status Date
                </TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.items.map((item, i) => (
                    <TableRow key={i}>
                        <TableCell align="left">
                            <Tooltip
                                title={
                                    <ConversionInfo
                                        item={item}
                                        isPromotion={true}
                                    />
                                }
                                classes={{ tooltip: classes.tooltipWidth }}
                                arrow
                            >
                                <Box>
                                    {lib.DateTimeFormat(Date.parse(item[0]))}
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                            <Tooltip
                                title={<ClickInfo item={item} label={false} />}
                                classes={{ tooltip: classes.tooltipWidth }}
                                arrow
                            >
                                <Chip
                                    label={lib.DateTimeFormat(
                                        Date.parse(item[6])
                                    )}
                                    variant="outlined"
                                />
                            </Tooltip>
                        </TableCell>
                        <TableCell align="left">{item[2]}</TableCell>
                        <TableCell align="left">{item[10]}</TableCell>
                        <TableCell align="left">
                            <Chip label={item[5]} />
                        </TableCell>
                        {/* <TableCell align="left">{item[1]}</TableCell>
                    <TableCell align="left">{item[2]}</TableCell>
                    <TableCell align="left">{item[3]}</TableCell>
                    <TableCell align="left">{item[4]}</TableCell> */}
                        {/* <TableCell align="left">{item[7]}</TableCell> */}
                        {/* <TableCell align="left">{item[8]}</TableCell> */}
                        {/* <TableCell align="left">{item[9]}</TableCell> */}
                        {/* <TableCell align="left">{item[11]}</TableCell>
                    <TableCell align="left">{item[12]}</TableCell> */}
                        {/* <TableCell align="left">{item[13]}</TableCell> */}
                        <TableCell align="left">
                            <Currency num={item[16]} />
                        </TableCell>
                        <TableCell align="left">{item[14]}</TableCell>
                        {/* <TableCell align="left">{item[15]}</TableCell> */}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
