import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import * as api from '../api';
import * as lib from '../lib';
import { Title } from './Title';
import { CacheContext, ConfigContext } from './Context';
import { UserContext } from './user';
import * as XLSX from 'xlsx';
import { ItemSelecter } from './Selecter';
import { firestore } from 'firebase';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    property: {
        width: '200px',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 260,
    },
    rowtitle: {
        width: '300px',
    },
    floatRight: {
        // margin: 0,
        // top: 90,
        // bottom: 'auto',
        // left: 'auto',
        // right: 20,
        // position: 'fixed',
        // position: 'absolute',
        float: 'right',
    },
    titleHelp: {
        marginLeft: 20,
    },
}));

type LinkToProps = {
    href: string;
    color?:
        | 'inherit'
        | 'initial'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error';
};
export const LinkTo: React.FC<LinkToProps> = ({ href, color, children }) => {
    const history = useHistory();
    return (
        <Link
            color={color}
            href={href}
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                history.push(href);
            }}
        >
            {children}
        </Link>
    );
};

export type CurrencyProps = {
    num: number;
    price?: number;
};
export const Currency: React.FC<CurrencyProps> = ({ num, price }) => {
    if (!num || num === 0) {
        return <div />;
    }
    const p = price ? num * price : num;
    const n = new Intl.NumberFormat(undefined, {
        style: 'decimal',
    }).format(p);
    return <Chip label={`¥${n}`} variant="outlined" />;
};

export type ReportData = {
    items: any[];
    promotionId?: string;
    promotionName?: string;
    siteId?: string;
    siteName?: string;
    price?: number;
};

export const wrapAPI = async (h: string, params: api.SummarizeParam, cache) => {
    const enabled = true;
    const key = new URLSearchParams(params as any).toString();
    let data;
    if (enabled && cache.value && key in cache.value) {
        console.log('cache hit ---');
        data = cache.value[key];
    } else {
        data = await api.API.summarize(h, params);
        cache.value[key] = data as any[];
        cache.setValue(cache.value);
    }
    return data;
};

export const compDocumentSnapshot = (
    a: firebase.firestore.DocumentSnapshot,
    b: firebase.firestore.DocumentSnapshot
) => {
    const an = a.get('displayName');
    const bn = b.get('displayName');
    if (an > bn) {
        return 1;
    } else if (an < bn) {
        return -1;
    } else {
        return 0;
    }
};

export const useSummarizeAPI = (params: api.SummarizeParam) => {
    const [items, setItems] = useState(null as any[]);
    const cache = useContext(CacheContext);
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);

    useEffect(() => {
        if (!params) {
            return;
        }
        setItems(null);
        (async () => {
            // console.log('API params', params);
            const data = await wrapAPI(config.apiEndpoint, params, cache);
            setItems(data);
        })();
    }, [userStatus, params]);
    return items;
};

const snapshotIds = (snaps: firebase.firestore.DocumentSnapshot[]) => {
    return snaps.map((v) => v.id);
};

const usePromotions = () => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.promotions(
                userStatus.user.email,
                config.serviceId
            );
            setItems(snaps);
        })();
    }, [userStatus]);

    return items;
};

const usePromotionSites = (id: string) => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.approvalByPromotionId(id);
            const promise = snaps.map((snap) => {
                const sid = snap.get('site');
                return store.site(sid);
            });
            const sites = await Promise.all(promise);
            sites.sort(compDocumentSnapshot);
            setItems(sites);
        })();
    }, [id]);

    return items;
};

const usePromotion = (id: string) => {
    const [item, setItem] = useState(
        null as firebase.firestore.DocumentSnapshot
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.promotion(id);
            setItem(snaps);
        })();
    }, [id]);

    return item;
};

const useSites = () => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const userStatus = useContext(UserContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.sites(
                userStatus.user.email,
                config.serviceId
            );
            setItems(snaps);
        })();
    }, [userStatus]);

    return items;
};

const useSitePromotions = (id: string) => {
    const [items, setItems] = useState(
        [] as firebase.firestore.DocumentSnapshot[]
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.approvalBySiteId(id);
            const promise = snaps.map((snap) => {
                const sid = snap.get('promotion');
                return store.promotion(sid);
            });
            const promotions = await Promise.all(promise);
            promotions.sort(compDocumentSnapshot);
            setItems(promotions);
        })();
    }, [id]);

    return items;
};

const useSite = (id: string) => {
    const [item, setItem] = useState(
        null as firebase.firestore.DocumentSnapshot
    );
    const config = useContext(ConfigContext);
    const store = new api.Store(config.environment);

    useEffect(() => {
        (async () => {
            const snaps = await store.site(id);
            setItem(snaps);
        })();
    }, [id]);

    return item;
};

export type Period = {
    id: string;
    name: string;
    start: string;
    end: string;
};
export const periodList: Period[] = [
    {
        id: 'today',
        name: lib.DateFormat(lib.Today()) + ' Today',
        start: lib.Today().toISOString(),
        end: lib.Tommorow().toISOString(),
    },
    {
        id: 'yesterday',
        name: lib.DateFormat(lib.BeforeDay(1)) + ' Yesterday',
        start: lib.BeforeDay(1).toISOString(),
        end: lib.Today().toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(0)),
        name: lib.MonthFormat(lib.Month(0)) + ' Current month',
        start: lib.Month(0).toISOString(),
        end: lib.Month(1).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-1)),
        name: lib.MonthFormat(lib.Month(-1)) + ' Last month',
        start: lib.Month(-1).toISOString(),
        end: lib.Month(0).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-2)),
        name: lib.MonthFormat(lib.Month(-2)),
        start: lib.Month(-2).toISOString(),
        end: lib.Month(-1).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-3)),
        name: lib.MonthFormat(lib.Month(-3)),
        start: lib.Month(-3).toISOString(),
        end: lib.Month(-2).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-4)),
        name: lib.MonthFormat(lib.Month(-4)),
        start: lib.Month(-4).toISOString(),
        end: lib.Month(-3).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-5)),
        name: lib.MonthFormat(lib.Month(-5)),
        start: lib.Month(-5).toISOString(),
        end: lib.Month(-4).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-6)),
        name: lib.MonthFormat(lib.Month(-6)),
        start: lib.Month(-6).toISOString(),
        end: lib.Month(-5).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-7)),
        name: lib.MonthFormat(lib.Month(-7)),
        start: lib.Month(-7).toISOString(),
        end: lib.Month(-6).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-8)),
        name: lib.MonthFormat(lib.Month(-8)),
        start: lib.Month(-8).toISOString(),
        end: lib.Month(-7).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-9)),
        name: lib.MonthFormat(lib.Month(-9)),
        start: lib.Month(-9).toISOString(),
        end: lib.Month(-8).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-10)),
        name: lib.MonthFormat(lib.Month(-10)),
        start: lib.Month(-10).toISOString(),
        end: lib.Month(-9).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-11)),
        name: lib.MonthFormat(lib.Month(-11)),
        start: lib.Month(-11).toISOString(),
        end: lib.Month(-10).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-12)),
        name: lib.MonthFormat(lib.Month(-12)),
        start: lib.Month(-12).toISOString(),
        end: lib.Month(-11).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-13)),
        name: lib.MonthFormat(lib.Month(-13)),
        start: lib.Month(-13).toISOString(),
        end: lib.Month(-12).toISOString(),
    },
    {
        id: lib.MonthFormat(lib.Month(-14)),
        name: lib.MonthFormat(lib.Month(-14)),
        start: lib.Month(-14).toISOString(),
        end: lib.Month(-13).toISOString(),
    },
];

type ReportSiteViewProps = {};

export const ReportPromotionView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const promotions = usePromotions();
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        if (!promotions || promotions.length === 0) {
            return;
        }
        setParams({
            start: period.start,
            end: period.end,
            promotionId: snapshotIds(promotions),
            siteId: null,
            dateLabel: period.name,
            promotionLabel: '',
            siteLabel: 'z',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [promotions, period, nonAffiliate, allUA]);

    return (
        <ReportViewBase
            title="Promotion's Report"
            items={snapshotToReportData(
                items,
                1,
                promotions,
                (s) => s.get('displayName'),
                (s) => `/reports/promotions/${s.id}`
            )}
            filename="PromotionReport"
            sheetname="PromotionReport"
            isGross={true}
            selecter={
                <ItemSelecter
                    items={periodList}
                    current={period}
                    setCurrent={setPeriod}
                />
            }
            setNonAffiliate={setNonAffiliate}
            setAllUA={setAllUA}
        />
    );
};

export const ReportPromotionSiteView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const { pid } = useParams<Record<string, string | undefined>>();
    const promotion = usePromotion(pid);
    const sites = usePromotionSites(pid);
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        setParams({
            start: period.start,
            end: period.end,
            promotionId: [pid],
            siteId: null,
            dateLabel: period.name,
            promotionLabel: '',
            siteLabel: '',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [period, nonAffiliate, allUA]);

    if (!promotion) {
        return <div></div>;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <LinkTo color="inherit" href="/reports/promotions">
                    Promotion's Report
                </LinkTo>
            </Breadcrumbs>
            <ReportViewBase
                title={promotion.get('displayName')}
                items={snapshotToReportData(
                    items,
                    3,
                    sites,
                    (snap) => snap.get('displayName'),
                    (snap) => `/reports/promotions/${pid}/${snap.id}`
                )}
                filename="PromotionReport"
                sheetname="PromotionReport"
                isGross={true}
                selecter={
                    <ItemSelecter
                        items={periodList}
                        current={period}
                        setCurrent={setPeriod}
                    />
                }
                setNonAffiliate={setNonAffiliate}
                setAllUA={setAllUA}
            />
        </>
    );
};

export const ReportPromotionSiteDetailView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const { pid, sid } = useParams<Record<string, string | undefined>>();
    const promotion = usePromotion(pid);
    const site = useSite(sid);
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        setParams({
            start: period.start,
            end: period.end,
            promotionId: [pid],
            siteId: [sid],
            dateLabel: '',
            promotionLabel: '',
            siteLabel: '',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [period, nonAffiliate, allUA]);

    if (!promotion || !site) {
        return <div></div>;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <LinkTo color="inherit" href="/reports/promotions">
                    Promotion's Report
                </LinkTo>
                <LinkTo color="inherit" href={`/reports/promotions/${pid}`}>
                    {promotion.get('displayName')}
                </LinkTo>
            </Breadcrumbs>
            <ReportViewBase
                title={site.get('displayName')}
                items={itemsToReportData(items)}
                filename="PromotionReport"
                sheetname="PromotionReport"
                isGross={true}
                selecter={
                    <ItemSelecter
                        items={periodList}
                        current={period}
                        setCurrent={setPeriod}
                    />
                }
                setNonAffiliate={setNonAffiliate}
                setAllUA={setAllUA}
            />
        </>
    );
};

export const ReportSiteView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const sites = useSites();
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        if (!sites || sites.length === 0) {
            return;
        }
        setParams({
            start: period.start,
            end: period.end,
            promotionId: null,
            siteId: snapshotIds(sites),
            dateLabel: period.name,
            promotionLabel: 'z',
            siteLabel: '',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [sites, period, nonAffiliate, allUA]);

    return (
        <ReportViewBase
            title="Site's Report"
            items={snapshotToReportData(
                items,
                3,
                sites,
                (s) => s.get('displayName'),
                (s) => `/reports/sites/${s.id}`
            )}
            filename="SiteReport"
            sheetname="SiteReport"
            isGross={false}
            selecter={
                <ItemSelecter
                    items={periodList}
                    current={period}
                    setCurrent={setPeriod}
                />
            }
            setNonAffiliate={setNonAffiliate}
            setAllUA={setAllUA}
        />
    );
};

export const ReportSitePromotionView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const { sid } = useParams<Record<string, string | undefined>>();
    const site = useSite(sid);
    const promotions = useSitePromotions(sid);
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        setParams({
            start: period.start,
            end: period.end,
            promotionId: null,
            siteId: [sid],
            dateLabel: period.name,
            promotionLabel: '',
            siteLabel: '',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [period, nonAffiliate, allUA]);

    if (!site) {
        return <div></div>;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <LinkTo color="inherit" href="/reports/sites">
                    Site's Report
                </LinkTo>
            </Breadcrumbs>
            <ReportViewBase
                title={site.get('displayName')}
                items={snapshotToReportData(
                    items,
                    1,
                    promotions,
                    (snap) => snap.get('displayName'),
                    (snap) => `/reports/sites/${sid}/${snap.id}`
                )}
                filename="SiteReport"
                sheetname="SiteReport"
                isGross={false}
                selecter={
                    <ItemSelecter
                        items={periodList}
                        current={period}
                        setCurrent={setPeriod}
                    />
                }
                setNonAffiliate={setNonAffiliate}
                setAllUA={setAllUA}
            />
        </>
    );
};

export const ReportSitePromotionDetailView: React.FC<ReportSiteViewProps> = () => {
    const config = useContext(ConfigContext);
    const [params, setParams] = useState(null);
    const { pid, sid } = useParams<Record<string, string | undefined>>();
    const promotion = usePromotion(pid);
    const site = useSite(sid);
    const [period, setPeriod] = useState(periodList[0]);
    const items = useSummarizeAPI(params);
    const [nonAffiliate, setNonAffiliate] = useState(false);
    const [allUA, setAllUA] = useState(false);

    useEffect(() => {
        setParams({
            start: period.start,
            end: period.end,
            promotionId: [pid],
            siteId: [sid],
            dateLabel: '',
            promotionLabel: '',
            siteLabel: '',
            serviceId: config.serviceId,
            sessionMode: config.sessionMode,
            nonAffiliate: nonAffiliate,
            allUA: allUA,
        });
    }, [period, nonAffiliate, allUA]);

    if (!promotion || !site) {
        return <div></div>;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <LinkTo color="inherit" href="/reports/sites">
                    Site's Report
                </LinkTo>
                <LinkTo color="inherit" href={`/reports/sites/${sid}`}>
                    {site.get('displayName')}
                </LinkTo>
            </Breadcrumbs>
            <ReportViewBase
                title={promotion.get('displayName')}
                items={itemsToReportData(items)}
                filename="SiteReport"
                sheetname="SiteReport"
                isGross={false}
                selecter={
                    <ItemSelecter
                        items={periodList}
                        current={period}
                        setCurrent={setPeriod}
                    />
                }
                setNonAffiliate={setNonAffiliate}
                setAllUA={setAllUA}
            />
        </>
    );
};

type ReportViewBaseProps = {
    title: string;
    items: ReportDataType[];
    filename: string;
    sheetname: string;
    isGross: boolean;
    selecter?: React.ReactElement;
    setNonAffiliate?: React.Dispatch<React.SetStateAction<boolean>>;
    setAllUA?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReportViewBase: React.FC<ReportViewBaseProps> = ({
    title,
    items,
    filename,
    sheetname,
    isGross,
    selecter,
    setNonAffiliate,
    setAllUA,
}) => {
    const classes = useStyles();

    return (
        <>
            <Title>
                {title}
                {/* <Chip className={classes.titleHelp} label="Last 30 days" /> */}
                <Box className={classes.floatRight}>
                    {setAllUA && (
                        <Tooltip
                            title="bot accesses"
                            // title={
                            //     allUA
                            //         ? 'exclude bot accesses'
                            //         : 'include bot accesses'
                            // }
                        >
                            <Checkbox
                                aria-label="allUA"
                                icon={<CloudOffIcon />}
                                checkedIcon={<CloudQueueIcon />}
                                name="allUA"
                                onChange={(e) => {
                                    setAllUA(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}

                    {setNonAffiliate && (
                        <Tooltip title="Include non affiliate click">
                            <Checkbox
                                aria-label="nonAffiliate"
                                icon={<VisibilityOffIcon />}
                                checkedIcon={<VisibilityIcon />}
                                name="nonAffiliate"
                                onChange={(e) => {
                                    setNonAffiliate(e.target.checked);
                                }}
                            />
                        </Tooltip>
                    )}

                    <Tooltip title="Download">
                        <IconButton
                            aria-label="download"
                            onClick={(e) => {
                                lib.saveAsXlsx(
                                    mapReportToCSV(items),
                                    filename,
                                    sheetname
                                );
                            }}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Title>

            {selecter && selecter}

            {!items ? (
                <LinearProgress />
            ) : (
                <ItemTable items={items} isGross={isGross} />
            )}
        </>
    );
};

type ItemTableProps = {
    items: ReportDataType[];
    isGross: boolean;
};

export const ItemTable: React.FC<ItemTableProps> = ({ items, isGross }) => {
    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Impression</TableCell>
                    <TableCell align="left">Click</TableCell>
                    <TableCell align="left">Conversion</TableCell>
                    <TableCell align="left">CVR</TableCell>
                    <TableCell align="left">Approved</TableCell>
                    <TableCell align="left">Denied</TableCell>
                    <TableCell align="left">Pending</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item, i) => (
                    <TableRow key={i}>
                        <TableCell
                            className={classes.rowtitle}
                            component="th"
                            scope="row"
                        >
                            {item.link ? (
                                <LinkTo href={item.link}>{item.name}</LinkTo>
                            ) : (
                                item.name
                            )}
                        </TableCell>
                        <TableCell align="left">{item.impression}</TableCell>
                        <TableCell align="left">{item.click}</TableCell>
                        <TableCell align="left">
                            {item.conversion}{' '}
                            <Currency
                                num={
                                    isGross
                                        ? item.conversionGross
                                        : item.conversionNet
                                }
                            />
                        </TableCell>
                        <TableCell align="left">{item.cvr}</TableCell>
                        <TableCell align="left">
                            {item.approved}{' '}
                            <Currency
                                num={
                                    isGross
                                        ? item.approvedGross
                                        : item.approvedNet
                                }
                            />
                        </TableCell>
                        <TableCell align="left">
                            {item.denied}{' '}
                            <Currency
                                num={
                                    isGross
                                        ? item.approvedGross
                                        : item.approvedNet
                                }
                            />
                        </TableCell>
                        <TableCell align="left">
                            {item.pending}{' '}
                            <Currency
                                num={
                                    isGross
                                        ? item.pendingGross
                                        : item.pendingNet
                                }
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

type ReportDataType = {
    name: string;
    link: string;
    impression: number;
    click: number;
    conversion: number;
    cvr: string;
    approved: number;
    denied: number;
    pending: number;
    conversionGross: number;
    conversionNet: number;
    approvedGross: number;
    approvedNet: number;
    deniedGross: number;
    deniedNet: number;
    pendingGross: number;
    pendingNet: number;
};

const itemsToReportData = (items: any[]): ReportDataType[] => {
    if (!items) {
        return null;
    }
    const data = items.map((item) => {
        return {
            name: item[0],
            link: null,
            impression: 0,
            click: item[5] ? (item[5] as number) : 0,
            conversion: item[6] ? (item[6] as number) : 0,
            cvr: lib.CVR(item[5], item[6]),
            approved: 0,
            denied: 0,
            pending: item[6] ? (item[6] as number) : 0,
            conversionGross: item[10] ? (item[10] as number) : null,
            conversionNet: item[11] ? (item[11] as number) : null,
            approvedGross: item[12] ? (item[12] as number) : null,
            approvedNet: item[13] ? (item[13] as number) : null,
            deniedGross: item[14] ? (item[14] as number) : null,
            deniedNet: item[15] ? (item[15] as number) : null,
            pendingGross: item[16] ? (item[16] as number) : null,
            pendingNet: item[17] ? (item[17] as number) : null,
        };
    });
    return data;
};

const snapshotToReportData = (
    items: any[],
    itemsSerchIndex: number,
    snaps: firebase.firestore.DocumentSnapshot[],
    name: (snap: firebase.firestore.DocumentSnapshot) => string,
    link: (snap: firebase.firestore.DocumentSnapshot) => string
): ReportDataType[] => {
    if (!items) {
        return null;
    }
    const search = (id: string) => {
        for (let i = 0; i < items.length; i++) {
            if (items[i][itemsSerchIndex] == id) {
                return items[i];
            }
        }
        return null;
    };
    const data = snaps.map((snap) => {
        const item = search(snap.id);
        if (item) {
            return {
                name: name(snap),
                link: link(snap),
                impression: 0,
                click: item[5] ? (item[5] as number) : 0,
                conversion: item[6] ? (item[6] as number) : 0,
                cvr: lib.CVR(item[5], item[6]),
                approved: 0,
                denied: 0,
                pending: item[6] ? (item[6] as number) : 0,
                conversionGross: item[10] ? (item[10] as number) : null,
                conversionNet: item[11] ? (item[11] as number) : null,
                approvedGross: item[12] ? (item[12] as number) : null,
                approvedNet: item[13] ? (item[13] as number) : null,
                deniedGross: item[14] ? (item[14] as number) : null,
                deniedNet: item[15] ? (item[15] as number) : null,
                pendingGross: item[16] ? (item[16] as number) : null,
                pendingNet: item[17] ? (item[17] as number) : null,
            };
        } else {
            return {
                name: name(snap),
                link: link(snap),
                impression: 0,
                click: 0,
                conversion: 0,
                cvr: '-',
                approved: 0,
                denied: 0,
                pending: 0,
                conversionGross: 0,
                conversionNet: 0,
                approvedGross: 0,
                approvedNet: 0,
                deniedGross: 0,
                deniedNet: 0,
                pendingGross: 0,
                pendingNet: 0,
            };
        }
    });
    return data;
};

const mapReportToCSV = (items: ReportDataType[]) => {
    const head: any[][] = [
        [
            'Name',
            'Impression',
            'Click',
            'Conversion',
            'CVR',
            'Approved',
            'Denied',
            'Pending',
        ],
    ];
    const body = items.map((item) => [
        item.name,
        item.impression,
        item.click,
        item.conversion,
        item.cvr,
        item.approved,
        item.denied,
        item.pending,
    ]);
    return head.concat(body);
};
